@font-face {
  font-family: minecraftFont;
  src: url(/src/fonts/Minecraft.ttf);
}

body {
  background-image: url(/src/dirt-background.png);
  background-color: orange;
  font-family: minecraftFont;
  height: 100vh;
  background-size: cover;
}

.top {
  border: solid 4px #555;
  outline: solid 2px black;
  border-radius: 50px;
  background: #212121;
  border-radius: 5px;
  display: block;
}

#first {
  width: 220px;
}

#second {
  margin-top: 20px;
}

td {
  color: white;
  padding: 10px;
}

ul {
  width: max-content;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  margin-top: 20px;
}

li {
  list-style: none;
  display: inline;
  margin-left: 10px;
}

li:nth-of-type(1) {
  margin-left: 0;
}

table {
  border-collapse: collapse;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: max-content;
  margin-top: 50px;
}

.app-container {
  width: max-content;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -60px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext {
  opacity: 0;
  transition: opacity 0.5s;
}

.tooltip:hover .tooltiptext {
  opacity: 1;
}

footer{
  position: absolute;
  bottom: 0px;
  background-color: rgba(22, 22, 22, 0.9);
  width: calc(100vw);
  border-radius: 5px;
  height: 64px;
}

.footer-container{
  position: relative;
  color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
}

footer a{
  color: yellow;
  text-decoration: none;
}